// Disable Context Menu
{
    document.addEventListener('contextmenu', event => event.preventDefault());
}

// Disable Screen Capture
{
    document.addEventListener('keyup', (e) => {
        if (e.key == 'PrintScreen') {
            navigator.clipboard.writeText('');
            // alert('Screenshots disabled!');
        }
    });
}

// Disable Ctrl+P
{
    document.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.key == 'p') {
            // alert('This section is not allowed to print or export to PDF');
            e.cancelBubble = true;
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    });
}

{
    /* Credits */
    /* Louis Hoebregts */
    /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
}


